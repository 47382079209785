import React from 'react';

function RestartButton(props){
  return (
    <button
      title='Restart'
      onClick={props.onClick}
      className={'game-button restart'}
    >
        <img src='reload.svg' alt='Restart' title='Restart' width="50px" />
    </button>
  );
}
export default RestartButton;