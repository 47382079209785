import './styles/App.css';
import Game from './components/Game';
function App() {
  return (
    <div className="App">
      <div className="noise-background"/>
      <header>
        <h1 aria-label="The Conway Life Game"/>
        <div className="container">
          <span className="center">The Conway</span>
          <div className="stack" style={{'--st': 3}}>
            <span style={{'--i': 0}}>LIFE</span>
            <span style={{'--i': 1}}>LIFE</span>
            <span style={{'--i': 2}}>LIFE</span>
          </div>
          <span className="center">Game</span>
        </div>
      </header>
      <Game/>
      <footer>
        <p>Made with <img src='/logo192.png' width='15px' alt="react" /> and <img src='/heart.svg' width="15px" alt='<3' /> by Francisco Gómez</p>
        <p>Credits:</p>
        <ul>
          <li><a href="https://www.flaticon.es/iconos-gratis/game-boy-advance" title="game boy advance iconos">Game boy advance - rukanicon - Flaticon</a></li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
