import React from 'react';

function PlayStopButton(props){
  return (
    <button
      title={(props.isRunning)?'Pause':'Play'}
      onClick={(props.isRunning)?props.pause:props.play}
      className={'game-button '+((props.isRunning)?'pause':'play')}
    >
        <img src={(props.isRunning)?'pause.svg':'play.svg'} alt={(props.isRunning)?'pause':'play'} width="50px" />
    </button>
  );
}
export default PlayStopButton;