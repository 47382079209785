import {React, useState} from 'react';
import { ChromePicker } from 'react-color';

function ColorSelector(props){
  const [toggle, setToggle] = useState(false);
  const handleToggle = (value)=>{
    if(value === 'close'){
      console.log('shit')
      setToggle(false);
    }else if (value === 'open'){
      setToggle(true);
    }else{
      setToggle(!toggle);
    }
  }
  return (
    <div className='colorSelector' onBlur={()=>{handleToggle('close')}}>
      <div className={'colorBlock '+ ((toggle)?'active':'inactive')} onClick={()=>{handleToggle()}}></div>
      <div className={'picker-container ' + ((toggle)?'active':'inactive') }>
        <ChromePicker disableAlpha={true} color={ props.value }  onChangeComplete={ props.onChange } />
      </div>
    </div>
  );
}
export default ColorSelector;