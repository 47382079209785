import Layouts from "./Layouts";
import Builder from "./Builder";

export const availableBoardLayouts = [
    'empty', 'infinite1', 'infinite2', 'infinite3', 'oscilator1', 'oscilator2', 'oscilator3', 'acorn', 'gosperGliderGun'
]

export const BuildBoard = (type = 'empty', cols = 0, rows = 0, position = null) =>{
    if(cols === 0 || rows === 0) return [];
    const  board    = Builder.empty(cols, rows);
    let layout      = [];
    switch (type) {
        case 'empty':
            return Builder.empty(cols, rows);
        case 'infinite1':
            if(!position) position = 'center';
            layout = Layouts.infinite1();
            break;
        case 'infinite2':
            if(!position) position = 'center';
            layout = Layouts.infinite2();
            break;
        case 'infinite3':
            if(!position) position = 'center';
            layout = Layouts.infinite3();
            break;
        case 'oscilator1':
            if(!position) position = 'center';
            layout = Layouts.oscilator1();
            break;
        case 'oscilator2':
            if(!position) position = 'center';
            layout = Layouts.oscilator2();
            break;
        case 'oscilator3':
            if(!position) position = 'center';
            layout = Layouts.oscilator3();
            break;
        case 'acorn':
            if(!position) position = 'center';
            layout = Layouts.acorn();
            break;
        case 'gosperGliderGun':
            if(!position) position = 'top-center';
            layout = Layouts.gosperGliderGun();
            break;
        default:
            return Builder.empty(cols, rows);
    }
    return Builder.printLayout(board, layout, cols, rows, position);
}
