const Layouts = {
    infinite1: ()=>{
        return [
            [false, false, false, false, false, false, true, false],
            [false, false, false, false, true,  false, true, true],
            [false, false, false, false, true,  false, true, false],
            [false, false, false, false, true,  false, false, false],
            [false, false, true,  false, false, false, false, false],
            [true, false,  true,  false, false, false, false, false],
        ];
    },
    infinite2: ()=>{
        return [
            [true,  true,  true,  false, true],
            [true,  false, false, false, false],
            [false, false, false, true,  true],
            [false, true,  true,  false, true],
            [true,  false, true,  false, true],
        ];
    },
    infinite3: ()=>{
        return [
            [true, true, true, true, true, true, true, true, false, true, true, true, true, true, false, false, false, true, true, true, false, false, false, false, false, false, true, true, true, true, true, true, true, false, true, true, true, true, true],
        ];
    },
    oscilator1: ()=>{
        return [
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, true,  true,  true,  false, false, false, true,  true,  true,  false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, true,  false, false, false, false, true,  false, true,  false, false, false, false, true,  false],
            [false, true,  false, false, false, false, true,  false, true,  false, false, false, false, true,  false],
            [false, true,  false, false, false, false, true,  false, true,  false, false, false, false, true,  false],
            [false, false, false, true,  true,  true,  false, false, false, true,  true,  true,  false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, true,  true,  true,  false, false, false, true,  true,  true,  false, false, false],
            [false, true,  false, false, false, false, true,  false, true,  false, false, false, false, true,  false],
            [false, true,  false, false, false, false, true,  false, true,  false, false, false, false, true,  false],
            [false, true,  false, false, false, false, true,  false, true,  false, false, false, false, true,  false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, true,  true,  true,  false, false, false, true,  true,  true,  false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
        ];
    },
    oscilator2: ()=>{
        return [
            [false, false, false, false, false, false, false, false, false],
            [false, false, true,  false, false, false, true,  false, false],
            [false, false, true,  true,  false, true,  true,  false, false],
            [false, false, false, true,  false, true,  false, false, false],
            [false, true,  false, true,  false, true,  false, true, false],
            [false, true,  true,  false, false, false, true,  true, false],
            [false, false, true,  false, false, false, true,  false, false],
            [false, false, false, false, false, false, false, false, false],
        ];
    },
    oscilator3: ()=>{
        return [
            [false, false, false, false, true,  true,  false, false, false, false],
            [false, false, false, true,  true,  true,  true,  false, false, false],
            [false, false, false, false, false, false, false, false, false, false],
            [false, true,  false, true,  false, false, true,  false, true,  false],
            [true,  true,  false, false, false, false, false, false, true,  true],
            [true,  true,  false, false, false, false, false, false, true,  true],
            [false, true,  false, true,  false, false, true,  false, true,  false],
            [false, false, false, false, false, false, false, false, false, false],
            [false, false, false, true,  true,  true,  true,  false, false, false],
            [false, false, false, false, true,  true,  false, false, false, false],
        ];
    },
    acorn: ()=>{
        return [
            [false, true,  false, false, false, false, false],
            [false, false, false, true,  false, false, false],
            [true,  true,  false, false, true,  true,  true],
        ];
    },
    gosperGliderGun: ()=>{
        return [
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true,  false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true,  false, true,  false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, true,  true,  false, false, false, false, false, false, true,  true,  false, false, false, false, false, false, false, false, false, false, false, false, true,  true],
            [false, false, false, false, false, false, false, false, false, false, false, true,  false, false, false, true,  false, false, false, false, true,  true,  false, false, false, false, false, false, false, false, false, false, false, false, true,  true],
            [true,  true, false, false, false, false, false, false, false, false,  true,  false, false, false, false, false, true,  false, false, false, true,  true,  false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [true,  true, false, false, false, false, false, false, false, false,  true,  false, false, false, true,  false, true,  true,  false, false, false, false, true,  false, true,  false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, true,  false, false, false, false, false, true,  false, false, false, false, false, false, false, true,  false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, true,  false, false, false, true,  false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, true,  true,  false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
        ];
    },
}
export default Layouts;