import React from 'react';

function IntervalInput(props){
  return (
    <label>
      Frequency: 
      <input
        type="number"
        onChange={props.onChange}
        value={props.value}
        className={'game-input fequency'}
      />
    </label>
  );
}
export default IntervalInput;