const setPosition = (position, cols, rows, layoutWidth, layoutHeight) =>{
    let x, y, xFinal, yFinal = 0;
    switch (position) {
        case 'top-left':
            x         = 0;
            y         = 0;
            break;
        case 'top-center':
            x         = Math.floor(((cols - layoutWidth)/2));
            y         = 0;
            break;
        case 'top-right':
            x         = cols - layoutWidth;
            y         = 0;
            break;
        case 'center':
            x         = Math.floor(((cols - layoutWidth)/2));
            y         = Math.floor(((rows - layoutHeight)/2));
            break;
        case 'left':
            x         = 0;
            y         = Math.floor(((rows - layoutHeight)/2));
            break;
        case 'right':
            x         = cols - layoutWidth;
            y         = Math.floor(((rows - layoutHeight)/2));
            break;
        case 'bottom-left':
            x         = 0;
            y         = rows - layoutHeight
            break;
        case 'bottom-center':
            x         = Math.floor(((cols - layoutWidth)/2));
            y         = rows - layoutHeight;
            break;
        case 'bottom-right':
            x         = cols - layoutWidth;
            y         = rows - layoutHeight;
            break;
        default:
            x         = 0;
            y         = 0;
            break;
    }
    xFinal    = x + layoutWidth;
    yFinal    = y + layoutHeight;
    return {x, y, xFinal, yFinal}
}

const Builder = {
    empty: (cols = 0, rows = 0)=>{
        if(cols === 0 || rows === 0) return [];
        let board = [];
        const row = Array(cols).fill(false);
        for (let y = 0; y < rows; y++) {
          board[y] = [...row];
        }
        return board;
    },
    printLayout: (board, layout, cols, rows, position = 'center') =>{
        const layoutWidth   = layout[0].length;
        const layoutHeight  = layout.length;
        //return default Board if not enough space
        if(rows <= layoutHeight || cols <= layoutWidth) return board;
        //set print space
        const {x, y, xFinal, yFinal} = setPosition(position, cols, rows, layoutWidth, layoutHeight);
        let newBoard    = [...board];
        let xPointer    = 0;
        let yPointer    = 0;
        //print Layout in given Board
        for (let i = 0; i < board.length; i++) {
            xPointer = 0;
            for (let j = 0; j < board[0].length; j++) {
                if((i >= y && i< yFinal) && (j >= x && j< xFinal)){
                    newBoard[i][j] = layout[yPointer][xPointer];
                    xPointer++;
                }else{
                    newBoard[i][j] = board[i][j];
                }
            }
            if(i >= y && i<= yFinal){
                yPointer++;
            }
        }
        return newBoard;
    }
}
export default Builder;