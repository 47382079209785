import React from 'react';
function Cell(props){
  return (
    <div
      onClick={props.onClick}
      className={'cell ' + ((props.isOn)?'on':'')}
      style={{
        'width': 'var(--cell-width)'
      }}
    />
  );
}
export default Cell;