import React from 'react';

function LayoutSelect(props){
  return (
    <select
      onChange={props.onChange}
      value={props.value}
      className={'game-select change-layout'}
    >
        {
          props.options.map((option, index) => {
            return (
              <option key={'op-'+index} value={option}>
                {option}
              </option>
            );
          })
        }
    </select>
  );
}
export default LayoutSelect;