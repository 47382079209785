import React from 'react';
import Cell from "./Cell";
import PlayStopButton from './PlayStopButton';
import RestartButton from './RestartButton';
import LayoutSelect from './LayoutSelect';
import IntervalInput from './IntervalInput';
import ColorSelector from './ColorSelector';
import { availableBoardLayouts, BuildBoard } from '../hooks/boardLayout/BuildBoard';
const WIDTH     = 50;
const HEIGHT    = 30;

const initialBoardLayouts = ['infinite1', 'infinite2', 'infinite3', 'gosperGliderGun'];

class Game extends React.Component {
  constructor() {
    super();
    this.rows = HEIGHT;
    this.cols = WIDTH;
    this.state.boardLayout  = initialBoardLayouts[Math.floor(Math.random() * initialBoardLayouts.length)];;
    this.state.board        = BuildBoard(this.state.boardLayout, this.cols, this.rows);
  }
  state = { board: [], boardLayout: '', livingCells: 0, iteration: 0, isRunning: true, interval:300, color: '#42BFDD' }
  componentDidMount(){
    this.runGame();
  }
  //GAME MANAGEMENT
  //Change Color
  handleClick(x,y){
    let board = [...this.state.board];
    board[x][y] = !board[x][y];
    this.setState({
      board: board
    });
  }
  runGame = () => {
    this.setState({ isRunning: true });
    this.timeoutHandler = window.setTimeout(() => { this.runIteration(); }, 0);
  }
  stopGame = () => {
    this.setState({ isRunning: false });
    if (this.timeoutHandler) {
      window.clearTimeout(this.timeoutHandler);
      this.timeoutHandler = null;
    }
  }
  restartGame = ()=>{
    this.stopGame();
    const newBoard  = BuildBoard(this.state.boardLayout, this.cols, this.rows);
    this.setState({ board: newBoard, iteration: 0});
    this.runGame();
  }
  handleChangeBoardLayout = (event) =>{
    this.stopGame();
    const newLayout = (availableBoardLayouts.includes(event.target.value))?event.target.value:availableBoardLayouts[0];
    const newBoard  = BuildBoard(newLayout, this.cols, this.rows);
    this.setState({ board: newBoard, boardLayout: newLayout, iteration: 0});
    this.runGame();
  }
  handleInterval = (event) =>{
    const newInterval = (isNaN(event.target.value) || event.target.value <= 0)?300:event.target.value;
    this.setState({ interval: newInterval});
  }
  handleColorChange = (color, event) => {
    this.setState({ color: color.hex});
  }
  //ITERATIONS
  runIteration() {
    if(!this.state.isRunning) return;
    const board     =  [...this.state.board];
    let iteration   = this.state.iteration;
    let livingCells = 0;
    let newBoard    =  BuildBoard('empty', this.cols, this.rows);
    for (let x = 0; x < this.rows; x++) {
      for (let y = 0; y < this.cols; y++) {
        let count = this.calculateNeighbors(x,y,board);
        if (board[x][y]) {
          newBoard[x][y] = count === 2 || count === 3;
        } else if (!board[x][y] && count === 3) {
          newBoard[x][y] = true;
        }
        if(newBoard[x][y]) livingCells++;
      }
    }
    this.setState({ board: newBoard,  iteration: iteration+1, livingCells: livingCells});
    this.timeoutHandler = window.setTimeout(() => { this.runIteration(); }, this.state.interval);
  }
  calculateNeighbors(x,y, board){
    let count = 0;
    const dirs = [[-1, -1], [-1, 0], [-1, 1], [1, 1], [1, 0], [1, -1], [0, 1], [0, -1]];
    for (let i = 0; i < dirs.length; i++) {
      const dir = dirs[i];
      let x1 = x + dir[0];
      let y1 = y + dir[1];
      if (x1 >= 0 && x1 < this.rows && y1 >= 0 && y1 < this.cols && board[x1][y1]) {
        count++;
      }
    }
    return count;
  }
  //Print Cells ands Rows
  printRows(){
    let rows = [];
    const board = [...this.state.board];
    if(board.length<1) return;
    for (let x = 0; x < this.rows; x++) {
      rows[x] = <div className="row" key={'r-'+x}>{
        board[x].map((val, y) => {
          return <Cell
            key={'c-'+y}
            isOn={val}
            onClick={() => this.handleClick(x,y)}
          />;
        })
      }</div>;
    }
    return rows;
  }

  render() {
    return (
      <div 
      className='game-container'
      style={{
        '--c-color': this.state.color,
        '--cell-width': 100/this.cols+'%'
      }}
      >
        <div className='game-interface'>
          <div className='game-info'>
            <p><b>Living Cells:</b> <span>{this.state.livingCells}</span></p>
            <p><b>Iteration:</b> <span>{this.state.iteration}</span></p>
          </div>
          <div className='game-buttons'>
            <IntervalInput onChange={this.handleInterval} value={this.state.interval} />
            <ColorSelector onChange={this.handleColorChange} value={this.state.color} />
            <LayoutSelect onChange={this.handleChangeBoardLayout} options={availableBoardLayouts} value={this.state.boardLayout} />
            <RestartButton onClick={this.restartGame} />
            <PlayStopButton play={this.runGame} pause={this.stopGame} isRunning={this.state.isRunning} />
          </div>
        </div>
        <div className="board">
          {this.printRows()}
        </div>
      </div>
    );
  }
}
export default Game;